import { anatomy } from "@chakra-ui/theme-tools";

export const productRowAnatomy: any = anatomy("surface").parts(
    "root",
    "link",
    "image",
    "headline",
    "description",
    "sizeColorBox",
    "sizeStyle",
    "colorStyle",
    "quantityText",
    "price",
    "priceNow",
    "priceBefore"
);
